export default {
  header: {
    partner: "Partners",
    about: "About",
    buy: "Buy crypto",
    aboutUs: "About Us",
  },
  footer: {
    desc: `Mixin Route Sp. z o.o. is a company registered in Street BARTYCKA 22B, office 21A, city WARSZAWA, index 00-716, country POLAND(Registration No.0001030006). Mixin Route has obtained the Virtual Asset Service Provider (VASP) registration from the Polish Tax Administration in Katowice (Registration No. RDWW-734). This registration enables the company to provide cryptocurrency exchange and custody services.`,
    partners: {
      title: "Partners",
      0: "Mixin Messenger",
      1: "Telegram Bot",
      2: "MixPay",
    },
    company: {
      title: "Company",
      0: "About Us",
      1: "Contact Us",
    },
    legal: {
      title: "Legal",
      0: "Privacy Policy",
      1: "Terms of Use",
    },
  },
  home: {
    top: {
      title: `Instantly <br /> <span>buy & sell crypto</span><br /> in your wallet`,
      countries: "Countries",
      currencies: "Fiat Currencies",
      contact: "Contact Sales",
      on: "Fiat to crypto on-ramp",
      onDesc: "Onboard new users without hassle with our end-to-end, non-custodial on-ramp.",
      off: "Crypto to fiat off-ramp",
      offDesc: "Give users the freedom to move back to fiat in just a few clicks with our truly global off-ramp.",
    },
    why: {
      title: "Why Mixin Route",
      0: {
        title: "Lowest Fees",
        content: "With the advantage of free transfers on the decentralized Mixin Network, users do not need to pay additional blockchain fees.",
      },
      1: {
        title: "Fast & Reliable",
        content: "Get crypto to your self-custody wallet instantly. Say goodbye to the long hold times & slippage on centralized exchanges.",
      },
      2: {
        title: "Global Coverage",
        content: "Available in 150+countries and territories, Support for major global payment/payout methods, with new options constantly added.",
      },
      3: {
        title: "Popular Assets",
        content: "Support more than 20 digital assets such as USDT, USDC, BTC, ETH, covering multiple L1 public chains and L2.",
      },
      4: {
        title: "Easy Compliance",
        content: "We handle KYC, AML, and regulations on all transactions coming through our widget.",
      },
      5: {
        title: "Simple Integration",
        content: "Integrate Mixin Route into your project quickly with just a few lines of code using our Web SDK.",
      },
    },
    support: {
      title: "Support for All Businesses",
      desc: "We’re available on hundreds of apps and websites, creating the easiest buying experience for crypto or NFTs. Think of us as the express checkout for the crypto world.",
      items: [
        {
          title: "DeFi",
          desc: "We help your DeFi project put your financial innovation front and center, while we handle the more mundane “fiat payments” part.",
        },
        {
          title: "Social Fi",
          desc: "We handle KYC, AML, and regulations on all transactions coming through our widget.",
        },
        {
          title: "NFT",
          desc: "We allow NFT Marketplaces to focus on the experience of actually creating and trading NFTs.",
        },
        {
          title: "Game Fi",
          desc: "We empower your play-to-earn game to realize your full economic potential without breaking the gaming experience.",
        },
      ],
    },
    code: {
      title: "Just a few lines of<br /> <span>code</span>",
      desc: "Your dev team can set up your on- and off-ramp in minutes with the help of our in-house dedicated partner team, or use our APIs for a deeper integration.",
      soon: "Coming Soon",

      file: "implementation.js",
      content: `
`,
    },
    customer: {
      title: "Hear from our customers",
      list: [
        {
          content: "Mixin Route provides a complete suite of decentralized solutions to help my customers to buy Bitcoin by fiat, no sacrifice of the Bitcoin decentralized nature.",
          name: "Thorb",
          position: "Founder and CEO, MixPay",
        },
      ],
    },
    started: {
      content: `Let's get your <br /> <span>integration</span> started`,
      contact: "Contact sales",
    },
  },
  about: {
    title: `We believe <span class="text-[#3076FF]">buying crypto</span> shouldn't be so hard and expensive`,
    info: {
      time: {
        title: "2017",
        content: "Founded in Christmas 2017 as a small team of less than 20 people, our mission is to provide convenience, security and privacy in digital assets and messages.",
      },
      user: {
        title: "1M+",
        content: "More than 1 million users use Mixin Safe-driven social, wallet, DeFi, games and other products, which are deeply loved by users for their safety and ease of use.",
      },
      asset: {
        title: "$1B+",
        content: "The Mixin team assists users in managing more than US$1 billion in assets. The service is stable and there have been no security incidents, which is deeply trusted by users.",
      },
    },
    mission: {
      title: "Our mission",
      content: "We build open source software that always puts security, privacy and decentralization first.",
    },
    qa: {
      title: "Frequently Asked Questions",
      desc: `Don't hesitate to contact us if you can't find your answers here`,
      list: [
        {
          q: "Why develop Mixin Route?",
          a: "With our technology and product advantages, we hope to help customers hold cryptocurrencies with confidence and pass them on to future generations safely and easily.",
        },
        {
          q: "Who developed Mixin Route?",
          a: "We are the team who developed Mixin Network, and our team members have more than 10 years of experience in blockchain and Bitcoin technology. Mixin Network is a public decentralized ledger launched in 2018 and has been running stably since then, with assets exceeding $1B and zero security incidents.",
        },
        {
          q: `Where is Mixin Route's company located?`,
          a: "Mixin Route LLC is a start-up company registered in Poland.",
        },
        {
          q: "What is the relationship between Mixin Route and Mixin Network?",
          a: "The wallet solution of Mixin Route is based on Mixin Network, and the developers behind Mixin Route also developed Mixin Network.",
        },
      ],
    },
  },
  contact: {
    title: "Contact us",
    fullName: "Name(required)",
    email: "Email(required)",
    businessType: "Business Type(required)",
    businessTypeMap: {
      exchange: "Exchange",
      fund: "Fund",
      project: "DAO",
      otc: "OTC",
      payment: "Payment",
      mining: "Mining Pool",
      other: "Other",
    },
    message: "Message(required)",
    submit: "Submit",
  },
  service: {
    title: "Mixin Route Terms of Service",
    desc: "Here’s a quick summary of the key points in our Terms of Service:",
    subtitle: "Mixin Global Terms of Service",
  },
  toast: {
    submit: {
      success: "Submit successfully.",
      fail: "Submit failed.",
    },
  },
}
