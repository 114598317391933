import { createI18n } from 'vue-i18n';
import en from './en';
import zh from './zh';

const messages = {
  en,
  zh,
};

const locales = createI18n({
  warnHtmlMessage: false,
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages,
});

export default locales;
