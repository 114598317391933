import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import HomeView from "../views/HomeView.vue"
import AboutView from "../views/AboutView.vue"
import ServiceView from "../views/ServiceView.vue"
import Layout from "../layout/Layout.vue"
import PrivacyPolicyView from "../views/PrivacyPolicyView.vue"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "",
        name: "Home",
        component: HomeView,
      },
      {
        path: "/about",
        name: "About",
        component: AboutView,
      },
      {
        path: "/service",
        name: "Service",
        component: ServiceView,
      },
      {
        path: "/privacy_policy",
        name: "PrivacyPolicy",
        component: PrivacyPolicyView,
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
