import "@/assets/less/unocss.css"
import "@/assets/less/common.less"
import "@unocss/reset/tailwind-compat.css"

import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import locales from "./locales"

const app = createApp(App)

app.use(locales)
app.use(router)
app.mount("#app")
